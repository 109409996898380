import { getItemFromLocalStorage } from './local_storage_manger';
import moment from 'moment';

export function isPremiumUser() {
  let profileInfo = getItemFromLocalStorage('profileInfo');

  let endData = profileInfo?.subInfo?.info?.end_date;
  let isActive = profileInfo?.subInfo?.info?.status_desc == 'مفعلة';
  return isActive && moment(endData, 'DD-MM-YYYY').diff(moment(), 'days') >= 0;
}

export function showIntercom(context, show, hide) {
  if (typeof window === 'undefined') {
    return false;
  }
  let pathname = context?.pathname || '';
  let href = window?.location?.href || '';
  let userInfo = getItemFromLocalStorage('userInfo');
  let isInPage = pathname
    ? pathname.includes('/pricing') || pathname.includes('/payment')
    : href.includes('/pricing') || href.includes('/payment');

  let result = userInfo?.id > 0 && isInPage;
  if (result) {
    if (window.Intercom) {
      window.Intercom('update', { hide_default_launcher: false });
    }
  } else {
    if (window.Intercom) {
      window.Intercom('update', { hide_default_launcher: true });
    }
  }
  return result;
}

export function hideIntercomMessage() {
  if (window.Intercom) {
    window.Intercom('update', { hide_default_launcher: true });
  }
}

export function getIntercomInfo() {
  let userInfo = getItemFromLocalStorage('userInfo');
  if (userInfo) {
    return {
      name: userInfo.info.name,
      user_id: userInfo.id,
      email: userInfo.username,
    };
  } else {
    return {};
  }
}

function getParamValue(param) {
  try {
    if (window) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let value = (params[param] || '').toLowerCase();
      return value;
    }
  } catch (error) {}

  return '';
}

export function isManualCardEnabled(plan) {
  return true;
  if((plan.currencyCode || '').toLowerCase() != 'sar'){
    return true;
  }
  return getParamValue('cardenabled') != 'false'

}
export function isCheckoutEnabled(plan) {
  return getParamValue('ckoenabled') == 'true';
  // return !plan.trial && (plan.currencyCode || '').toLowerCase() == 'sar';
  // return ['arb83'].includes(getParamValue('promo')) && (plan.currencyCode || '').toLowerCase() == 'sar' && (plan.period || '').toString() == '12';
}
export function isMadaEnabled(plan) {
  return getParamValue('madaenabled') == 'true';
  // return !plan.trial && (plan.currencyCode || '').toLowerCase() == 'sar';
  // return ['arb83'].includes(getParamValue('promo')) && (plan.currencyCode || '').toLowerCase() == 'sar' && (plan.period || '').toString() == '12';
}
export function isApplePayCheckoutEnabled(plan) {
  return getParamValue('ckoapenabled') == 'true';
  // return false;
  // return !plan.trial && (plan.currencyCode || '').toLowerCase() == 'sar';
  //return (plan.currencyCode || '').toLowerCase() == 'sar';
  // return ['arb83'].includes(getParamValue('promo')) && (plan.currencyCode || '').toLowerCase() == 'sar' && (plan.period || '').toString() == '12';
}
export function isAppleGooglePayStripeEnabled(plan) {
  return true && getParamValue('ckoapenabled') != 'true';
}
